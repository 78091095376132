import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Thanks for making it to the end of my website!
        </p>
      <small class='website-rights'>Arman Hafizi © 2023</small>
      </section>
    </div>
  );
}

export default Footer;
