import React from 'react';
import {HashRouter as Router} from 'react-router-dom';
import './App.css';
import HeroSection from './components/HeroSection';
import Cards from './components/Cards';
import Footer from './components/Footer';

function App() {
  return (
    <>
      <Router>
          <HeroSection />
          <Cards />
          <Footer />
      </Router>
    </>
  );
}

export default App;
