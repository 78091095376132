import React, {useState} from 'react';
import { Link } from 'react-router-dom';

function CardItem(props) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <li className='cards__item'>
        {props.path ? (
        <Link className='cards__item__link' to={props.path}>
          <figure className='cards__item__pic-wrap'
            data-category={props.label}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <img className='cards__item__img' alt='Static' src={props.src_img}/>
            {isHovered ? (
            <img className='cards__item__img' alt='GIF' src={props.src_gif}/>
          ) : (
            <img className='cards__item__img' alt='Static' src={props.src_img}/>
          )}
          </figure>
          <div className='cards__item__info'>
            <h5 className='cards__item__title'>{props.title}</h5>
            <h6 className='cards__item__text'>{props.text}</h6>
          </div>
        </Link>) : ( // else
          <a className='cards__item__link' href={props.file} target='_blank' rel='noopener noreferrer'>
          <figure className='cards__item__pic-wrap'
            data-category={props.label}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <img className='cards__item__img' alt='Static' src={props.src_img}/>
            {isHovered ? (
            <img className='cards__item__img' alt='GIF' src={props.src_gif}/>
          ) : (
            <img className='cards__item__img' alt='Static' src={props.src_img}/>
          )}
          </figure>
          <div className='cards__item__info'>
            <h5 className='cards__item__title'>{props.title}</h5>
            <h6 className='cards__item__text'>{props.text}</h6>
          </div>
        </a>
        )}
      </li>
    </>
  );
}

export default CardItem;
