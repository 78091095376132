// Cards.js
import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import { Link } from 'react-router-dom';

function Cards() {

  return (
    <div className='cards'>
      <div className='cards__container' style={{ backgroundImage: `url(images/bg-2.jpg)`, backgroundSize: 'cover' }}>
      <h1 id='portfolio-section' className='section-heading'>PORTFOLIO</h1>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src_img='images/head-rotation-0.png'
              src_gif='images/head-rotation.gif'
              title='Head Rotation Selection'
              text='The study tests four activation methods (dwell time, voice command, pinch gesture, steering wheel button) for a head-up display infotainment system using head rotation. We use a virtual driving simulator and eye tracker to assess distraction and cognitive load.'
              label='Paper'
              path=''
            />
            <CardItem
              src_img='images/invehicle-gestures-0.jpg'
              src_gif='images/invehicle-gestures.gif'
              title='In-vehicle Gestures'
              text='The study compares in-vehicle gestures (touchscreen swipes, midair gestures) with touchscreen taps as a baseline. Participants use these methods for target selection while simulating lane changes, measuring performance and distraction levels.'
              label='Paper'
              path='https://github.com/exii-uw/invehicle-gestures'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src_img='images/NIDS-0.png'
              src_gif='images/NIDS.gif'
              title='Network Intrusion Detection System'
              text='The paper proposes a framework for adapting deep learning models to evolving attack patterns in online IDS. It introduces sequential packet labeling and is evaluated on CIC-IDS2017 and CSE-CIC-IDS2018 datasets.'
              label='Paper'
              path='https://arxiv.org/abs/2303.02622'
            />
            <CardItem
              src_img='images/harris-0.jpeg'
              src_gif='images/harris.gif'
              title='Reserve in Hotel Program'
              text='The reserving feature in hotel programs was enhanced, resulting in a 9% increase in speed and 3% reduction in overhead.'
              label='Internship'
              path='https://www.harrisnewtech.ir/en/Harris/'
            />
            </ul>
        </div>
      </div>
      <div className='cards__container' style={{ backgroundImage: `url(images/bg-3.jpg)`, backgroundSize: 'cover' }}>
      <h1 id='projects-section' className='section-heading'>PROJECTS</h1>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src_img='images/gesture-recognition-0.png'
              src_gif='images/gesture-recognition.gif'
              title='Gesture Recognition - Reinforcement Learning'
              text='We propose a Deep Reinforcement Learning approach for training a classification system in hand gesture recognition for in-vehicle interactions. The results show a 94% accuracy achieved in a reasonable time frame.'
              label='Course Project'
              path='https://github.com/armanhafizi/ReinforcementLearning-GestureRecognition'
            />
            <CardItem
              src_img='images/voting-system.jpg'
              src_gif='images/voting-system.jpg'
              title='Voting System - Data and Network Security'
              text='A secure voting system using a combination of certificates, keys, encryption, and hashing for ensuring integrity, confidentiality, and authentication in the voting process.'
              label='Course Project'
              path='https://github.com/armanhafizi/DataNetworkSecurity-VotingSystemProject'
            />
            </ul>
        </div>
      </div>
      <div className='cards__container' style={{ backgroundImage: `url(images/bg-4.jpg)`, backgroundSize: 'cover' }}>
      <h1 id='resumecv-section' className='section-heading'>RESUME & CV</h1>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src_img='images/Resume-cover.png'
              src_gif='images/Resume-cover.png'
              // title='Gesture Recognition - Reinforcement Learning'
              // text='We propose a Deep Reinforcement Learning approach for training a classification system in hand gesture recognition for in-vehicle interactions. The results show a 94% accuracy achieved in a reasonable time frame.'
              label='Resume'
              file='files/Resume.pdf'
            />
            <CardItem
              src_img='images/CV-cover.png'
              src_gif='images/CV-cover.png'
              // title='Voting System - Data and Network Security'
              // text='A secure voting system using a combination of certificates, keys, encryption, and hashing for ensuring integrity, confidentiality, and authentication in the voting process.'
              label='Cirriculum Vitae'
              file='files/CV.pdf'
            />
            </ul>
        </div>
      </div>
      <div className='cards__container' style={{ backgroundImage: `url(images/bg-5.jpg)`, backgroundSize: 'cover' }}>
      <h1 id='activities-section' className='section-heading'>ACTIVITIES</h1>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src_img='images/activities-0.jpeg'
              src_gif='images/activities.gif'
              title='Physical Activities'
              text='I go rock climbing, hiking, and cycling. I also enjoy playing football, basketball, and volleyball. I have also got into chess recently.'
              // label='SPORTS'
              path=''
            />
            <CardItem
              src_img='images/movies-0.jpeg'
              src_gif='images/movies.gif'
              title='Movie Buff'
              text='I watch lots of movies and series every week, and enjoy discussing them with friends.'
              // label='Course Project'
              path='https://boxd.it/1iqYn'
            />
            </ul>
        </div>
      </div>
      <div className='cards__container' style={{ backgroundImage: `url(images/bg-6.jpg)`, backgroundSize: 'cover' }}>
      <h1 id='contactme-section' className='section-heading'>CONTACT ME</h1>
          <p style={{padding: '50px 25px', textAlign:'justify'}}>Shoot me a message if you have any questions or just want to chat!</p>
          <div class='social-icons'>
            <a class='social-icon-link Email'
              href='mailto:armanhafizi1998@gmail.com'
              aria-label='Email'
              target='_blank'
              rel='noreferrer'>
              <i class='fas fa-envelope' />
            </a>
            <Link
              class='social-icon-link LinkedIn'
              to='https://www.linkedin.com/in/armanhafizi/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </Link>
            <Link
              class='social-icon-link GitHub'
              to='https://github.com/armanhafizi'
              target='_blank'
              aria-label='GitHub'
            >
              <i class='fab fa-github' />
            </Link>
            <Link
              class='social-icon-link youtube'
              to='https://www.youtube.com/channel/UC9XhsURgbBykTF8hZeP76cw'
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link>
            {/* <Link
              class='social-icon-link twitter'
              to='https://twitter.com/xephaestus'
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
            <Link
              class='social-icon-link facebook'
              to='https://www.facebook.com/arman.hex.98'
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            <Link
              class='social-icon-link instagram'
              to='https://www.instagram.com/arman_hex/'
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link> */}
          </div>
      </div>
    </div>
  );
}

export default Cards;
